@import './variables.scss';
@import './defaults.scss';

.pay__main {
    position: relative;
    min-height: 100vh;
    width: 100%; 
    background: linear-gradient(140deg, $color2 0%, $color2 35%, $color1 100%);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}
.pay__container {
    position: relative;
    width: 390px;
    background-color: white;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 3px 20px 0px rgba(0, 0, 0, 0.1);
    padding: 40px 55px 20px 55px;
}
.pay__header {
    text-align: center;
}
.pay__logo {
    max-width: 100%;
}
.pay__information {
    position: relative;
    div {
        margin: 40px auto;
    }
}
.pay__field {
    position: relative;
}
.pay__submit {
    text-align: center;
}
.pay__button {
    color: white;
    font-weight: bold;
    background-color: $color1;
    padding: 10px 30px;
    border-radius: 25px;
    box-shadow: 0 10px 30px 0px $color1-opacity;
    font-size: 14px;
    border: none;
    &:hover {
        transition: 0.5s;
        box-shadow: 0 13px 33px 0px $color1-opacity;
        padding: 10px 30px;
        cursor: pointer;
    }
    &:focus, &:active {
        outline: none !important;
        box-shadow: none;
    }
}
.pay__footer {
    text-align: center;
    font-size: 14px;
    img {
        width: 14px;
        height: 14px;
        opacity: 0.5;
        display: inline;
    }
}
.pay__other {
    position: fixed;
    left: 20px;
    bottom: 20px;
}

// Pay status
.pay__status {
    font-weight: bold;
    color: black;
    text-align: center;
}
.pay__retry {
    color: white;
    font-weight: bold;
    background-color: $color1;
    padding: 10px 30px;
    border-radius: 25px;
    box-shadow: 0 10px 30px 0px $color1-opacity;
    &:hover {
        color: white;
        transition: 0.5s;
        box-shadow: 0 13px 33px 0px $color1-opacity;
        padding: 10px 36px;
    }
    &:focus, &:active {
        outline: none !important;
        box-shadow: none;
    }
}
.pay__status__text {
    margin-bottom: 20px !important;
    text-align: center;
}
.pay__status__icon {
    margin-bottom: 10px !important;
    animation: rotateAnim 0.5s ease-in-out;
    img {
        width: 50px;
    }
}
.pay__success {
    color: $status-green;
}
.pay__error {
    color: $status-red;
}
@keyframes rotateAnim {
    0% {
        transform: initial;
    }
    100% {
        transform: rotateZ(360deg);
        transform: rotateX(360deg);
    }
}



// Pay Input Fields
.pay__input {
    display: inline-block;
    width: 100%;
    padding: 10px 10px 10px 15px;
    font-weight: bold;
    color: black;
    background: #efefef;
    border: 0;
    border-radius: 3px;
    outline: 0;
    text-indent: 80px;
    transition: all .3s ease-in-out;
    font-size: 14px;
    
    &::-webkit-input-placeholder {
      color: #efefef;
      text-indent: 0;
      font-weight: 300;
    }
  
    + label {
      display: inline-block;
      position: absolute;
      top: 10px;
      left: 0;
      padding: 2px 15px;
      color: #032429;
      font-size: 11px;
      font-weight: 700;
      text-transform: uppercase;
      text-shadow: 0 1px 0 rgba(19,74,70,0);
      transition: all .3s ease-in-out;
      border-radius: 3px;
      background: rgba(122,184,147,0);
      pointer-events: none;
      
      &:after {
        position: absolute;
        content: "";
        width: 0;
        height: 0;
        top: 100%;
        left: 50%;
        margin-left: -3px;
        border-left: 3px solid transparent;
        border-right: 3px solid transparent;
        border-top: 3px solid rgba(122,184,147,0);
        transition: all .3s ease-in-out;
      }
    }
}
.pay__input:focus,
.pay__input:active {
    color: black;
    text-indent: 0;
    background: #fff;

    &::-webkit-input-placeholder {
      color: #aaa;
    }
    + label {
      color: #fff;
      text-shadow: 0 1px 0 rgba(19,74,70,.4);
      background: black;
      transform: translateY(-40px);
      
      &:after {
        border-top: 4px solid black;
      }
    }
}
.pay__watermark {
    position: absolute;
    right: 16px;
    bottom: 16px;
    width: 200px;
}